import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { fetch14, fetch15, fetch16, fetch3, fetch30, fetch4, fetch5, fetch6 } from '../../Apis/commonApis';
import { notify } from '../../constants/notifyconstants';
export let dataBussiness: any = null;
export let takerDataBusiness: any = null;
export let zatcaDataBusiness: any = null;

export const connections: any = createAsyncThunk('connections', async (body: any) => {
  try {
    const response = await fetch14(
      `${process.env.REACT_APP_BASEURL}/integration/connections?service=${body?.service}&page=${body?.page}`,
    );

    return response;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const getOdooCompanies: any = createAsyncThunk('getOdooCompanies', async (body: any) => {
  try {
    const response = await fetch14(
      `${process.env.REACT_APP_BASEURL}/integration/odoo/businesses?connection_id=${body}`,
    );

    return response?.data;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const getOdooCustomers: any = createAsyncThunk('getOdooCustomers', async (body: any) => {
  try {
    const response = await fetch14(
      `${process.env.REACT_APP_BASEURL}/integration/odoo/customers?connection_id=${body?.connection_id}&company_id=${body?.company_id}`,
    );

    return response?.data;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const getOdooJournals: any = createAsyncThunk('getOdooJournals', async (body: any) => {
  try {
    const response = await fetch14(
      `${process.env.REACT_APP_BASEURL}/integration/odoo/journals?connection_id=${body?.connection_id}&company_id=${body?.company_id}`,
    );

    return response?.data;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const getOdooDeliveryLocations: any = createAsyncThunk('getOdooDeliveryLocations', async (body: any) => {
  try {
    const response = await fetch14(
      `${process.env.REACT_APP_BASEURL}/integration/odoo/picking_types?connection_id=${body}`,
    );

    return response?.data;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const getOdooProducts: any = createAsyncThunk('getOdooProducts', async (body: any) => {
  try {
    const response = await fetch14(`${process.env.REACT_APP_BASEURL}/integration/odoo/products?connection_id=${body}`);

    return response?.data;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const getTakerProducts: any = createAsyncThunk('getTakerProducts', async (body: any) => {
  try {
    const response = await fetch14(`${process.env.REACT_APP_BASEURL}/integration/taker/products?connection_id=${body}`);

    return response?.data;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const getTakerModifiers: any = createAsyncThunk('getTakerModifiers', async (body: any) => {
  try {
    const response = await fetch14(
      `${process.env.REACT_APP_BASEURL}/integration/taker/modifiers?connection_id=${body}`,
    );

    return response?.data;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const getPaymentMethods: any = createAsyncThunk('getPaymentMethods', async (body: any) => {
  try {
    const response = await fetch14(
      `${process.env.REACT_APP_BASEURL}/integration/odoo/payment_methods?connection_id=${body?.connection_id}&company_id=${body?.company_id}`,
    );

    return response?.data;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const getTaxes: any = createAsyncThunk('getTaxes', async (body: any) => {
  try {
    const response = await fetch14(
      `${process.env.REACT_APP_BASEURL}/integration/odoo/taxes?connection_id=${body?.connection_id}&company_id=${body?.company_id}`,
    );

    return response?.data;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const zatcaDevicesList: any = createAsyncThunk('zatcaDevicesList', async body => {
  try {
    const response: any = await fetch6(`${process.env.REACT_APP_BASEURL}/select/devices`, body);
    return response;
  } catch (error: any) {
    notify(error?.response?.message, 'error');
    throw error;
  }
});

export const connectItems: any = createAsyncThunk('connectItems', async (body: any) => {
  try {
    const response = await fetch30(`${process.env.REACT_APP_BASEURL}/integration/items`, body);
    notify('Connected Successfully', 'success');
    return response;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const addConnectionApi: any = createAsyncThunk('addConnection', async body => {
  try {
    const response = await fetch30(`${process.env.REACT_APP_BASEURL}/integration/connections?service=odoo`, body);
    dataBussiness = response;
    return response;
  } catch (error: any) {
    notify(error?.message);

    return (dataBussiness = error?.response);
  }
});

export const addTakerConnectionApi: any = createAsyncThunk('addTakerConnection', async body => {
  try {
    const response = await fetch30(`${process.env.REACT_APP_BASEURL}/integration/connections?service=taker`, body);
    takerDataBusiness = response;
    return response;
  } catch (error: any) {
    notify(error?.message);

    return (takerDataBusiness = error?.response);
  }
});

export const addZatcaConnectionApi: any = createAsyncThunk('addZatcaConnection', async body => {
  try {
    const response = await fetch30(`${process.env.REACT_APP_BASEURL}/integration/connections?service=zatca`, body);
    zatcaDataBusiness = response;
    notify(response?.massage || 'ZATCA Connected Successfully', 'success');
    return response;
  } catch (error: any) {
    notify(error?.message);

    return (zatcaDataBusiness = error?.response);
  }
});

export const addConfigurationApi: any = createAsyncThunk('addConfiguration', async body => {
  try {
    const response = await fetch30(`${process.env.REACT_APP_BASEURL}/integration/configurations`, body);
    dataBussiness = response;
    return response;
  } catch (error: any) {
    notify(error?.message);

    return (dataBussiness = error?.response);
  }
});

export const editConnectionApi: any = createAsyncThunk('editConnection', async (body: any) => {
  try {
    const response = await fetch15(`${process.env.REACT_APP_BASEURL}/integration/connections/${body?.id}`, body?.data);
    return response;
  } catch (error: any) {
    notify(error?.message);

    return (dataBussiness = error?.response);
  }
});

export const deleteConnectionApi: any = createAsyncThunk('deleteConnection', async (body: any) => {
  try {
    const result = await fetch16(`${process.env.REACT_APP_BASEURL}/integration/connections/${body}`);
    return result;
  } catch (error: any) {
    notify(error?.response?.data?.message);
  }
});

export const connectionConfig: any = createAsyncThunk('connection/config', async body => {
  try {
    const response = await fetch14(`${process.env.REACT_APP_BASEURL}/integration/configurations?connection_id=${body}`);
    return response.data;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const addBranchApi: any = createAsyncThunk('addBranch', async body => {
  try {
    const response = await fetch5(`${process.env.REACT_APP_BASEURL}/odoo/auth`, body);
    dataBussiness = response;
    return response;
  } catch (error: any) {
    notify(error?.message);

    return (dataBussiness = error?.response);
  }
});

export const businessList: any = createAsyncThunk('businessList', async body => {
  try {
    const response = await fetch6(`${process.env.REACT_APP_BASEURL}/odoo/businesses`, body);
    return response;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const businessSync: any = createAsyncThunk('businessSync', async body => {
  try {
    const response = await fetch5(`${process.env.REACT_APP_BASEURL}/odoo/sync-business`, body);
    return response;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const companyId: any = createAsyncThunk('companyId', async body => {
  try {
    const response = await fetch6(`${process.env.REACT_APP_BASEURL}/odoo/business`, body);
    return response;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const products: any = createAsyncThunk('products', async (body: any) => {
  try {
    const response = await fetch6(`${process.env.REACT_APP_BASEURL}/odoo/products?company_id=${body.id}`);

    return response;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const branches: any = createAsyncThunk('branches', async (body: any) => {
  try {
    const response = await fetch6(`${process.env.REACT_APP_BASEURL}/odoo/branches?company_id=${body.id}`);

    return response;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const taxes: any = createAsyncThunk('taxes', async (body: any) => {
  try {
    const response = await fetch6(`${process.env.REACT_APP_BASEURL}/odoo/taxes?company_id=${body.id}`);
    return response;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const paymentMehods: any = createAsyncThunk('paymentMehods', async (body: any) => {
  try {
    const response = await fetch6(`${process.env.REACT_APP_BASEURL}/odoo/payment_methods?company_id=${body.id}`);
    return response;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const priceTags: any = createAsyncThunk('priceTags', async (body: any) => {
  try {
    const response = await fetch6(`${process.env.REACT_APP_BASEURL}/odoo/price_tags?company_id=${body.id}`);
    return response;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const customers: any = createAsyncThunk('customers', async (body: any) => {
  try {
    const response = await fetch6(`${process.env.REACT_APP_BASEURL}/odoo/customers?company_id=${body.id}`);
    return response;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});

export const syncObject: any = createAsyncThunk('syncObject', async (body: any) => {
  try {
    const response = await fetch5(`${process.env.REACT_APP_BASEURL}/odoo/sync`, body);
    notify('Sync Successfully', 'success');
    return response;
  } catch (error: any) {
    return notify(error?.response?.data?.message);
  }
});
const initialState = {
  dataStore: null,
  dataBussiness: null,
  takerDataBusiness: null,
  zatcaDataBusiness: null,
  connectionsList: {},
  CompaniesList: [],
  CustomersList: [],
  JournalsList: [],
  zatcadevicesListData: [],
  DeliveryLocationsList: [],
  ProductsList: [],
  TakerProductsList: [],
  TakerModifiersList: [],
  TaxesList: [],
  PaymentMethodsList: [],
  connectionConfigList: {},
  isConnectionLoaded: true,
  isCompaniesLoaded: true,
  isCustomersLoaded: true,
  isJournalsLoaded: false,
  isDeliveryLocationsLoaded: false,
  isProductsLoaded: true,
  isTakerProductsLoaded: true,
  isTakerModifiersLoaded: true,
  isTaxesLoaded: true,
  isPaymentMethodsLoaded: true,
  isConnectionConfigLoaded: false,
  businessListData: [],
  productsListData: [],
  customersListData: [],
  branchesListData: [],
  taxesListData: [],
  paymentMehodsListData: [],
  priceTagDataListData: [],
  companyIdData: [],
  companyId: null,
  isLoading: false,
  isLoadingBusinessList: false,
  isLoadingProducts: true,
  isLoadingSync: false,
  isDisconnected: false,
};
// Redux slice

const marketPlaceSlice: any = createSlice({
  name: 'marketPlace',
  initialState,
  reducers: {
    setStoreData(state = initialState, action: any) {
      state.dataStore = action.payload;
    },

    setPageLoading(state = initialState) {
      state.isLoading = true;
    },
    toggleDisconnected(state = initialState) {
      state.isDisconnected = !state.isDisconnected; // Toggle isDisconnected between true and false
    },
  },
  extraReducers: builder => {
    builder
      .addCase(connections.pending, (state: any, action) => {
        state.isConnectionLoaded = true;
      })
      .addCase(connections.fulfilled, (state: any, action) => {
        state.isConnectionLoaded = false;
        state.connectionsList = action.payload.data;
      })
      .addCase(getOdooCompanies.pending, (state: any, action) => {
        state.isCompaniesLoaded = true;
      })
      .addCase(getOdooCompanies.fulfilled, (state: any, action) => {
        state.isCompaniesLoaded = false;
        state.CompaniesList = action.payload;
      })
      .addCase(getOdooCustomers.pending, (state: any, action) => {
        state.isCustomersLoaded = true;
      })
      .addCase(getOdooCustomers.fulfilled, (state: any, action) => {
        state.isCustomersLoaded = false;
        state.CustomersList = action.payload;
      })
      .addCase(getOdooJournals.pending, (state: any, action) => {
        state.isJournalsLoaded = true;
      })
      .addCase(getOdooJournals.fulfilled, (state: any, action) => {
        state.isJournalsLoaded = false;
        state.JournalsList = action.payload;
      })
      .addCase(getOdooDeliveryLocations.pending, (state: any, action) => {
        state.isDeliveryLocationsLoaded = true;
      })
      .addCase(getOdooDeliveryLocations.fulfilled, (state: any, action) => {
        state.isDeliveryLocationsLoaded = false;
        state.DeliveryLocationsList = action.payload;
      })
      .addCase(getOdooProducts.pending, (state: any, action) => {
        state.isProductsLoaded = true;
      })
      .addCase(getOdooProducts.fulfilled, (state: any, action) => {
        state.isProductsLoaded = false;
        state.ProductsList = action.payload;
      })
      .addCase(getTakerProducts.pending, (state: any, action) => {
        state.isTakerProductsLoaded = true;
      })
      .addCase(getTakerProducts.fulfilled, (state: any, action) => {
        state.isTakerProductsLoaded = false;
        state.TakerProductsList = action.payload;
      })
      .addCase(getTakerModifiers.pending, (state: any, action) => {
        state.isTakerModifiersLoaded = true;
      })
      .addCase(getTakerModifiers.fulfilled, (state: any, action) => {
        state.isTakerModifiersLoaded = false;
        state.TakerModifiersList = action.payload;
      })
      .addCase(getPaymentMethods.pending, (state: any, action) => {
        state.isPaymentMethodsLoaded = true;
      })
      .addCase(getPaymentMethods.fulfilled, (state: any, action) => {
        state.isPaymentMethodsLoaded = false;
        state.PaymentMethodsList = action.payload;
      })
      .addCase(getTaxes.pending, (state: any, action) => {
        state.isTaxesLoaded = true;
      })
      .addCase(getTaxes.fulfilled, (state: any, action) => {
        state.isTaxesLoaded = false;
        state.TaxesList = action.payload;
      })
      .addCase(connectionConfig.pending, (state: any, action) => {
        state.isConnectionConfigLoaded = true;
      })
      .addCase(connectionConfig.fulfilled, (state: any, action) => {
        state.isConnectionConfigLoaded = false;
        state.connectionConfigList = action.payload;
      })
      .addCase(addBranchApi.pending, (state: any, action) => {
        state.isLoading = true;
      })
      .addCase(addBranchApi.fulfilled, (state: any, action) => {
        state.isLoading = false;
      })
      .addCase(addConnectionApi.pending, (state: any, action) => {
        state.isLoading = true;
      })
      .addCase(addConnectionApi.fulfilled, (state: any, action) => {
        state.isLoading = false;
      })
      .addCase(addTakerConnectionApi.pending, (state: any, action) => {
        state.isLoading = true;
      })
      .addCase(addTakerConnectionApi.fulfilled, (state: any, action) => {
        state.isLoading = false;
      })
      .addCase(addZatcaConnectionApi.pending, (state: any, action) => {
        state.isLoading = true;
      })
      .addCase(addZatcaConnectionApi.fulfilled, (state: any, action) => {
        state.isLoading = false;
      })
      .addCase(connectItems.pending, (state: any, action) => {
        state.isLoading = true;
      })
      .addCase(connectItems.fulfilled, (state: any, action) => {
        state.isLoading = false;
      })
      .addCase(addConfigurationApi.pending, (state: any, action) => {
        state.isLoading = true;
      })
      .addCase(addConfigurationApi.fulfilled, (state: any, action) => {
        state.isLoading = false;
      })
      .addCase(addConfigurationApi.rejected, (state: any, action) => {
        state.isLoading = false;
      })
      .addCase(editConnectionApi.pending, (state: any, action) => {
        state.isLoading = true;
      })
      .addCase(editConnectionApi.fulfilled, (state: any, action) => {
        state.isLoading = false;
      })
      .addCase(editConnectionApi.rejected, (state: any, action) => {
        state.isLoading = false;
      })
      .addCase(deleteConnectionApi.pending, (state: any, action) => {
        state.isLoading = true;
      })
      .addCase(deleteConnectionApi.fulfilled, (state: any, action) => {
        state.isLoading = false;
      })
      .addCase(deleteConnectionApi.rejected, (state: any, action) => {
        state.isLoading = false;
      })
      .addCase(businessList.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.isLoadingBusinessList = false;

        state.businessListData = action.payload;
      })
      .addCase(businessList.pending, (state: any, action) => {
        state.isLoadingBusinessList = true;
      })

      .addCase(zatcaDevicesList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(zatcaDevicesList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.zatcadevicesListData = action.payload;
      })
      .addCase(products.fulfilled, (state: any, action) => {
        state.isLoadingProducts = false;
        state.productsListData = action.payload;
      })
      .addCase(customers.pending, (state: any, action) => {
        state.isLoadingProducts = true;
      })
      .addCase(customers.fulfilled, (state: any, action) => {
        state.isLoadingProducts = false;
        state.customersListData = action.payload;
      })
      .addCase(products.pending, (state: any, action) => {
        state.isLoadingProducts = true;
        // state.isLoadingSync = true;
      })

      .addCase(taxes.fulfilled, (state: any, action) => {
        state.isLoadingProducts = false;

        state.taxesListData = action.payload;
      })
      .addCase(taxes.pending, (state: any, action) => {
        state.isLoadingProducts = true;
      })
      .addCase(branches.pending, (state: any, action) => {
        state.isLoadingProducts = true;
      })
      .addCase(paymentMehods.pending, (state: any, action) => {
        state.isLoadingProducts = true;
      })
      .addCase(priceTags.pending, (state: any, action) => {
        state.isLoadingProducts = true;
      })
      .addCase(branches.fulfilled, (state: any, action) => {
        state.isLoadingProducts = false;
        state.branchesListData = action.payload;
      })
      .addCase(paymentMehods.fulfilled, (state: any, action) => {
        state.isLoadingProducts = false;
        state.paymentMehodsListData = action.payload;
      })
      .addCase(priceTags.fulfilled, (state: any, action) => {
        state.isLoadingProducts = false;
        state.priceTagDataListData = action.payload;
      })
      .addCase(companyId.fulfilled, (state: any, action) => {
        state.isLoadingProducts = false;

        state.companyIdData = action.payload;
      })
      .addCase(companyId.pending, (state: any, action) => {
        state.isLoadingProducts = true;
      });
  },
});
export const { setStoreData, setPageLoading, toggleDisconnected } = marketPlaceSlice.actions;
export default marketPlaceSlice.reducer;
